import React from "react";
import OutletsOnMap from "@page-contents/outlets-on-map";

export default props => {
	const { location: { search } = {} } = props;
	const defaultCompany =
		search && search.split("?company=")
			? decodeURI(search.split("?company=")[1])
			: undefined;
	return (
		<OutletsOnMap
			pageContext={{ defaultType: "refill", defaultCompany }}
			rest={props}
		/>
	);
};
